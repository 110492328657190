import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const MeistPage = () => (
  <Layout>
    <Seo title="Meist" />
    <div className=" border-t border-gray-100">
      <div className="max-w-screen-xl mx-auto __contentstyles pt-12 pb-36">
        <h1>Meist</h1>
        <p>
          Elektrihind.ee pakub Sulle erapooletut ja sõltumatut võrdlust avatud
          elektriturul tegutsevate elektrimüüjate vahel. Ülevaatliku info põhjal
          saad valida just endale sobivaima elektripakkuja.
        </p>
        <p>
          Meie eesmärk on tuua läbipaistvust avatud elektriturule ja
          kindlustada, et Sa leiaksid enda jaoks parima pakkumise lihtsalt ja
          mugavalt, ilma et peaksid selleks oma väärtuslikku aega kulutama.
          Lisaks elektrihindade võrdlemisel on oluline mõista elektrimüüjate
          pakutavate pakettide sisulisi erinevusi nagu lepingu tähtaeg,
          katkestamistasu, elektri tootmise viis jms.
        </p>
        <p>
          Portaali kasutamine on täiesti tasuta. Täna on võimalik võrrelda
          kõikide Eesti eraklientidele suunatud elektrimüüjate pakette.
          Võrreldavad elektrimüüjad on Eesti Energia, 220 Energia, Elektrum
          (endine Latvenergo), Eesti Gaas, VKG Elektrivõrgud, Alexela Energia
          AS. Imatra lõpetas elektrimüügi 2021, nad müüsid enda elektriportfelli
          Elektrum Eestile.
        </p>
        <h2>Milleks Sulle elektrihind.ee portaal?</h2>
        <p>
          Lähtume Sinu huvist. Oleme sõltumatu abiline sobivaima elektrimüüja
          paketi valimisel. Sinu aja kokkuhoid. Mitme elektrimüüja hindade ja
          paketi tingimuste võrdlus ühest kohast. Sinu rahulolu. Tänu
          elektripakettide tingimuste ja hindade võrdlusele võid kindel olla, et
          teed parima valiku. Elektrihind.ee eesmärk on lisaks pakettide
          võrdlusele aidata uutele ja vanadele elektrimüüjatele kliente leida,
          jäädes samas sõltumatuks ja läbipaistvaks. Portaali Elektrihind.ee
          haldab Go OÜ ja portaal avati 12. oktoobril 2012. Elektrihind.ee
          käitamise rahastamise allikateks on reklaami-, vahendus- ja
          teenustasud. Saame vahendustasu portaali kaudu sõlmitud
          elektripakettide ning energiatoodete vahendamisest. Go OÜ Reg nr
          16055598 Kirjutage meile aadressil kiri@elektrihind.ee.
        </p>
      </div>
    </div>
  </Layout>
)

export default MeistPage
